import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiPosicaoEstoqueService extends ApiServiceReinfProd { 

    constructor(){
        super('estoque/posicao')  
    } 

    consultar(){ 
 
        return this.get_geral();
    } 
 
}