import { tokens } from "../theme";

export const tituloDashBoard = [
  {
    id: 1,
    titleCabecalho: "ESTOQUE",
    title: "EMPRESA MODELO",

    titleQuadro1: 12.361,
    subtitleQuadro1: "E-mails Enviados",
    progressQuadro1: "0.75",
    increaseQuadro1: "+14%",

    titleQuadro2: 431.225,
    subtitleQuadro2: "Vendas Obtidas",
    progressQuadro2: "0.50",
    increaseQuadro2: "+21%",

    titleQuadro3: 32.441,
    subtitleQuadro3: "Novos Clientes",
    progressQuadro3: "0.30",
    increaseQuadro3: "+5%",

    titleQuadro4: 1325.134,
    subtitleQuadro4: "Tráfego Recebido",
    progressQuadro4: "0.80",
    increaseQuadro4: "+43%",

    titleGraficoLinhas: "Receita gerada",
    valorGraficoLinhas: 58373.698,

    titleBar: "TITULO",
    titlePizza: "TITULO",

  },

]

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin"
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager"
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user"
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin"
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user"
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager"
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user"
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user"
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin"
  }
];

export const mockBarData2 = [
  {
    group: 'Jan',
    total: 80,
    totalColor: '#845EC2',
  },
  {
    group: 'Fev',
    total: 10,
    totalColor: '#845EC2',
  },
  {
    group: 'Mar',
    total: 40,
    totalColor: '#845EC2',
  },
  {
    group: 'Abr',
    total: 6,
    totalColor: '#845EC2',
  },
  {
    group: 'Mai',
    total: 40,
    totalColor: '#845EC2',
  },
  {
    group: 'Jun',
    total: 60,
    totalColor: '#845EC2',
  },
  {
    group: 'Jul',
    total: 65,
    totalColor: '#845EC2',
  },
  {
    group: 'Aug',
    total: 78,
    totalColor: '#845EC2',
  },
  {
    group: 'Set',
    total: 63,
    totalColor: '#845EC2',
  },
  {
    group: 'Out',
    total: 58,
    totalColor: '#845EC2',
  },
  {
    group: 'Nov',
    total: 43,
    totalColor: '#845EC2',
  },
  {
    group: 'Dec',
    total: 60,
    totalColor: '#845EC2',
  },

]


export const mockBarData3 = [
  {
    group: 'HERBICIDA',
    total: 19473683,
    totalColor: '#845EC2',
  },
  {
    group: 'ADUBO SOCA',
    total: 13246276,
    totalColor: '#845EC2',
  },
  {
    group: 'PRODUTO QUIMICO',
    total: 9051616,
    totalColor: '#845EC2',
  },
  {
    group: 'AUTO PECAS / VALMET',
    total: 7959116,
    totalColor: '#845EC2',
  },
  {
    group: 'CORRETIVO DE SOLO',
    total: 7156819,
    totalColor: '#845EC2',
  },

]

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197
  }
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022"
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021"
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022"
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022"
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021"
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022"
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022"
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021"
  }
];

export const mockTransactions = [
  {
    cod_item: "1",
    descricao: "DESCRICAO PRODUTO 1",
    qtd: 50.15,
  },
  {
    cod_item: "2",
    descricao: "DESCRICAO PRODUTO 2",
    qtd: 58,
  },
  {
    cod_item: "3",
    descricao: "DESCRICAO PRODUTO 3",
    qtd: 255,
  },
  {
    cod_item: "4",
    descricao: "DESCRICAO PRODUTO 4",
    qtd: 10.50,
  },
  {
    cod_item: "4",
    descricao: "DESCRICAO PRODUTO 4",
    qtd: 10,
  },
  {
    cod_item: "4",
    descricao: "DESCRICAO PRODUTO 4",
    qtd: 10,
  },
  {
    cod_item: "4",
    descricao: "DESCRICAO PRODUTO 4",
    qtd: 10,
  },

];

export const mockBarData = [
  {
    estado: "SP",

    produto1: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    produto2: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    produto3: 140,
    donutColor: "hsl(340, 70%, 50%)",
    produto4: 137,
    produto4Color: "hsl(229, 70%, 50%)",
  },
  {
    estado: "RJ",

    produto1: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    produto2: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    produto3: 29,
    donutColor: "hsl(275, 70%, 50%)",
    produto4: 127,
    produto4Color: "hsl(229, 70%, 50%)",
  },
  {
    estado: "BA",

    produto1: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    produto2: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    produto3: 152,
    donutColor: "hsl(256, 70%, 50%)",
    produto4: 147,
    produto4Color: "hsl(229, 70%, 50%)",
  },
  {
    estado: "MT",

    produto1: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    produto2: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    produto3: 83,
    donutColor: "hsl(9, 70%, 50%)",
    produto4: 122,
    produto4Color: "hsl(229, 70%, 50%)",
  },
  {
    estado: "AL",

    produto1: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    produto2: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    produto3: 35,
    donutColor: "hsl(285, 70%, 50%)",
    produto4: 152,
    produto4Color: "hsl(229, 70%, 50%)",
  },
  {
    estado: "PR",

    produto1: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    produto2: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    produto3: 18,
    donutColor: "hsl(76, 70%, 50%)",
    produto4: 133,
    produto4Color: "hsl(229, 70%, 50%)",
  },
  {
    estado: "SC",

    produto1: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    produto2: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    produto3: 49,
    donutColor: "hsl(274, 70%, 50%)",
    produto4: 156,
    produto4Color: "hsl(229, 70%, 50%)",
  }
];

export const mockPieData = [
  {
    id: "ALMOXARIFADO EXTERNO",
    label: "ALMOXARIFADO EXTERNO",
    value: 3700696,
    color: "hsl(278, 70%, 50%)"
  },
  {
    id: "Produto-2",
    label: "Produto-2",
    value: 3646517,
    color: "hsl(278, 70%, 50%)"
  },
  {
    id: "Produto-3",
    label: "ddd-3",
    value: 3246227,
    color: "hsl(291, 70%, 50%)"
  },
  {
    id: "Produto-4",
    label: "ssssss-4",
    value: 2172949,
    color: "hsl(229, 70%, 50%)"
  },
  {
    id: "Produto-5",
    label: "sasassa-5",
    value: 331829,
    color: "hsl(229, 70%, 50%)"
  },
  {
    id: "Produto-6",
    label: "sasassa-5",
    value: 180637,
    color: "hsl(229, 70%, 50%)"
  },
  {
    id: "Produto-7",
    label: "sasassa-5",
    value: 72873,
    color: "hsl(229, 70%, 50%)"
  },

];

export const mockLineData = [
  {
    id: "Produto-1",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "Jan",
        y: 101
      },
      {
        x: "Fev",
        y: 75
      },
      {
        x: "Mar",
        y: 36
      },
      {
        x: "Abr",
        y: 216
      },
      {
        x: "Mai",
        y: 35
      },
      {
        x: "Jun",
        y: 236
      },
      {
        x: "Jul",
        y: 88
      },
      {
        x: "Aug",
        y: 232
      },
      {
        x: "Set",
        y: 281
      },
      {
        x: "Out",
        y: 1
      },
      {
        x: "Nov",
        y: 35
      },
      {
        x: "Dec",
        y: 14
      }
    ]
  },
  {
    id: "Produto-2",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "Jan",
        y: 212
      },
      {
        x: "Fev",
        y: 190
      },
      {
        x: "Mar",
        y: 270
      },
      {
        x: "Abr",
        y: 9
      },
      {
        x: "Mai",
        y: 75
      },
      {
        x: "Jun",
        y: 175
      },
      {
        x: "Jul",
        y: 33
      },
      {
        x: "Aug",
        y: 189
      },
      {
        x: "Set",
        y: 97
      },
      {
        x: "Out",
        y: 87
      },
      {
        x: "Nov",
        y: 299
      },
      {
        x: "Dec",
        y: 251
      }
    ]
  },
  {
    id: "Produto-3",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "Jan",
        y: 191
      },
      {
        x: "Fev",
        y: 136
      },
      {
        x: "Mar",
        y: 91
      },
      {
        x: "Abr",
        y: 190
      },
      {
        x: "Mai",
        y: 211
      },
      {
        x: "Jun",
        y: 152
      },
      {
        x: "Jul",
        y: 189
      },
      {
        x: "Aug",
        y: 152
      },
      {
        x: "Set",
        y: 8
      },
      {
        x: "Out",
        y: 197
      },
      {
        x: "Nov",
        y: 107
      },
      {
        x: "Dec",
        y: 170
      }
    ]
  },
  {
    id: "Produto-4",
    color: tokens("dark").blueAccentNew[500],
    data: [
      {
        x: "Jan",
        y: 98
      },
      {
        x: "Fev",
        y: 69
      },
      {
        x: "Mar",
        y: 38
      },
      {
        x: "Abr",
        y: 205
      },
      {
        x: "Mai",
        y: 45
      },
      {
        x: "Jun",
        y: 226
      },
      {
        x: "Jul",
        y: 90
      },
      {
        x: "Aug",
        y: 235
      },
      {
        x: "Set",
        y: 290
      },
      {
        x: "Out",
        y: 100
      },
      {
        x: "Nov",
        y: 48
      },
      {
        x: "Dec",
        y: 22
      }
    ]
  },
];

