import React, { useEffect, useState, useRef } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { mockBarData } from '../data/mockData';
import ApiDashboardBarrasService from '../service/ApiDashboardBarrasService';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";

const BarChart = ({ isDashboard = false }) => {

    const tempo = process.env.REACT_APP_TEMPO_EXECUSSAO

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const timeToCallSomething = useRef(null);

    const [service, setService] = useState(new ApiDashboardBarrasService());

    const [record, setRecord] = useState([]);

    useEffect(() => {

        if (timeToCallSomething.current) {
            clearInterval(timeToCallSomething.current);
        }

        timeToCallSomething.current = setTimeout(fetchSomething, tempo);

        return () => clearInterval(timeToCallSomething.current);

    });

    const fetchSomething = () => {
        buscar();
    };

    function buscar() {

        service
            .consultar()
            .then(resposta => {

                setRecord(resposta.data)
 
            }).catch(error => {
                console.log(error)
            });

    }

    return (

        <>
            <ToastContainer position="top-right" duration />
 
            <ResponsiveBar
                data={record}
                onClick={(data) => {

                    toast.error(data["value"], { autoClose: 1500 });
  
                }}
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: colors.grey[100]
                            }
                        },
                        legend: {
                            text: {
                                fill: colors.grey[100]
                            }
                        },
                        ticks: {
                            line: {
                                stroke: colors.grey[100],
                                strokeWidth: 1
                            },
                            text: {
                                fill: colors.grey[100]
                            }
                        }
                    },
                    legends: {
                        text: {
                            fill: colors.grey[100]
                        }
                    },
                    tooltip: {
                        container: {
                            background: colors.primary[400],
                            color: colors.grey[100],
                        },
                    }
                }}

                keys={[
                    'entradas',
                    'saidas'
                ]}

                indexBy="mes_ano"
                margin={{ top: 30, right: 20, bottom: 40, left: 75 }}
                padding={0.6}
                innerPadding={1}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}

                valueFormat=" >-,.2f"

                colors={{ scheme: 'category10' }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}

                borderRadius={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            '1.1'
                        ]
                    ]
                }}

                enableLabel={false}
                axisTop={null}
                axisRight={null}
                //    axisBottom={{
                //        tickSize: 5,
                //        tickPadding: 5,
                //        tickRotation: 0,
                //        legend: isDashboard ? undefined : 'estado',
                //        legendPosition: 'middle',
                //       legendOffset: 32
                //   }}

                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: isDashboard ? undefined : 'Qtdade',
                    legendPosition: 'middle',
                    legendOffset: -70
                }}

                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}


                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'row',
                        justify: false,
                        translateX: 10,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 105,
                        itemHeight: -70,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}

                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " Ano / Mês: " + e.indexValue }}
            />
        </>
    )

}

export default BarChart