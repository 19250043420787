import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiListaEstoqueExterno extends ApiServiceReinfProd {

    constructor(){
        super('dashboard/lista/externo') 
               
    } 

    consultar(Filtro){

        return this.get_geral();
    }
 
}