import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiDashboardItensService extends ApiServiceReinfProd { 

    constructor(){
        super('dashboard/itens')  
    } 

    consultar(){ 
 
        return this.get_geral();
    } 
 
}