import React, { useEffect, useState, useRef } from 'react';
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData } from "../data/mockData";
import ApiDasboardlineService from "../service/ApiDasboardlineService";

const LineChart = ({ isDashboard = false }) => {

  const tempo = process.env.REACT_APP_TEMPO_EXECUSSAO

  const timeToCallSomething = useRef(null);

  const [service, setService] = useState(new ApiDasboardlineService());

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [record, setRecord] = useState([]);

  function buscar() {

    service
      .consultar()
      .then(resposta => {

        setRecord(resposta.data)
 
      }).catch(error => {
        console.log(error)
      });

  }

  const fetchSomething = () => {
    buscar();
  };

  useEffect(() => {

    if (timeToCallSomething.current) {
      clearInterval(timeToCallSomething.current);
    }

    timeToCallSomething.current = setTimeout(fetchSomething, tempo);

    return () => clearInterval(timeToCallSomething.current);

  });

  return (
    <ResponsiveLine
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
            color: colors.grey[100],
          },
        },
      }}
      curve="catmullRom"
      data={record}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
      margin={{ top: 50, right: 120, bottom: 50, left: 80 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}

      yFormat=" >-,.2f"

      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Ano/Mês",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickValues: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Valores R$",
        legendOffset: -70,
        legendPosition: "middle",
      }}

      enableGridX={true}
      enableGridY={isDashboard}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}

      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}

    />
  );
};

export default LineChart;
