import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiListaEstoqueObsoleto extends ApiServiceReinfProd {

    constructor(){
        super('dashboard/lista/obsoleto') 
               
    } 

    consultar(Filtro){

        return this.get_geral();
    }
 
}