import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiDashboardBarrasService extends ApiServiceReinfProd { 

    constructor(){
        super('estoque/entrada/saida')  
    } 

    consultar(){  

        return this.get_geral();
    } 
 
}