import ApiService from "../service/ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiDashboardService extends ApiServiceReinfProd { 

    constructor(){
        super('dashboard/cards')  
    } 

    consultar(){ 
 
        return this.get_geral();
    } 
 
}