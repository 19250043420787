import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiPosicaoEstoqueObsoletoService extends ApiServiceReinfProd { 

    constructor(){
        super('estoque/obsoleto')  
    } 
 
    consultar(){ 
 
        return this.get_geral();
    } 
 
}