import React from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, InputBase } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ConexaolinedIcon from "@mui/icons-material/LogoutRounded";
import { AuthConsumer } from '../../service/ProvedorAutenticacao';

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useProSidebar } from "react-pro-sidebar";

import { useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';


const Topbar = (props) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const history = useHistory();

  return (
    <Box display="flex" justifyContent="space-between">

      <Box display="flex">

        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >

            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
      <Box display="flex">

        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (

            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>

        <Tooltip
          title="Fazer Logout"  >
          <IconButton onClick={props.deslogar}>
            <ConexaolinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};


export default () => (
  <AuthConsumer>
    {(context) => (
      <Topbar deslogar={context.encerrarSessao} />
    )}
  </AuthConsumer>
)
