import React, { useState, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import ApiListaEstoqueExterno from '../../service/ApiListaEstoqueExterno';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Grid from "@mui/material/Unstable_Grid2";
import 'primereact/resources/themes/saga-blue/theme.css';
//import 'primereact/resources/primereact.css';

export default function EstoqueExterno() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [nodes, setNodes] = useState([{}]);
  const [saldo, setSaldo] = useState(0);

  const [service, setService] = useState(new ApiListaEstoqueExterno());

  useEffect(() => {
    buscar();
  }, []);

  const buscar = () => {

    service
      .consultar()
      .then(resposta => {

        const lista = resposta.data;

        setNodes(resposta.data);

        var saldoGeral = 0;

        setSaldo(saldoGeral);

      }).catch(error => {
        console.log(error)
      });

  }

  return (

    <div>

      <div style={{ margin: 10 }}>
        <h2>Almoxarifado Externo</h2>
      </div>

      <TreeTable tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} removableSort value={nodes} scrollable scrollHeight="650px" style={{ margin: 10 }} onScroll={true} >

        <Column sortable field="cod_almoxarifado" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Cod. Almoxarifado" expander style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column sortable field="descricaoalmox" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Descrição" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column sortable field="codgrupo" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Cod.Grupo" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column sortable field="descricaoGrupo" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Descrição" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column sortable field="item" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Item" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column sortable field="descricaoMaterial" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Descrição" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column field="qtd" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Qtd" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>
        <Column sortable field="saldo" headerStyle={{ fontSize: 12, color: colors.grey[100], backgroundColor: colors.primary[400] }} header="Saldo" style={{ fontSize: 10, backgroundColor: colors.primary[400], color: colors.grey[100] }}></Column>

      </TreeTable>

    </div>

  );
}