import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiEstoqueMinimoMaximoServices extends ApiServiceReinfProd { 

    constructor(){
        super('estoque/minimo/maximo')  
    } 

    consultar(){ 
 
        return this.get_geral();
    } 
 
}