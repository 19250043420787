import axios from 'axios'
 
const baseURL = process.env.REACT_APP_API_URL_p 
 
export const httpClient = axios.create({
    baseURL: baseURL,
    withCredentials: true   
})

class ApiServiceProd {
 
    constructor(apiurl){
        this.apiurl = apiurl;
    }

    static registrarToken(token){
        if(token){
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
        }        
    }
 
    post(url, objeto){
        const requestUrl = `${this.apiurl}${url}`
        return httpClient.post(requestUrl, objeto);
    }

    put(url, objeto){
        const requestUrl = `${this.apiurl}${url}`
        return httpClient.put(requestUrl, objeto);
    }

    delete(url){
        const requestUrl = `${this.apiurl}${url}`
        return httpClient.delete(requestUrl)
    }

    get(url){
        const requestUrl = `${this.apiurl}${url}`
        return httpClient.get(requestUrl)
    }

    get_geral(){
         const requestUrl = `${this.apiurl}`
        return httpClient.get(requestUrl)
    }

}


export default ApiServiceProd;