import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiPosicaoSemMovService extends ApiServiceReinfProd { 

    constructor(){
        super('estoque/sem/movimento')  
    } 

    consultar(){ 
 
        return this.get_geral();
    } 
 
}