
import ApiService from '../service/ApiService';
import ApiServiceProd from '../service/ApiServiceProd';


import ErroValidacao from '../validacao/index';

class UsuarioService extends ApiServiceProd {

    constructor(){ 
        super('/api/usuarios')
    }

    autenticar(credenciais){
        return this.post('/autenticar', credenciais)
    }
  
    salvar(usuario){
        return this.post('', usuario);
    }
  
    atualizar(usuario){
        return this.put(`/${usuario.username}`, usuario);
    }

    validar(usuario){
        const erros = []

        if(!usuario.username){
            erros.push('O campo Nome é obrigatório.')
        }
  
        if(!usuario.senha || !usuario.senhaRepeticao){
            erros.push('Digite a senha 2x.')
        }else if( usuario.senha !== usuario.senhaRepeticao ){
            erros.push('As senhas não batem.')
        }        

        if(erros && erros.length > 0){
            throw new ErroValidacao(erros);
        }
    }

}

export default UsuarioService;