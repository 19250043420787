
import React, { useEffect, useState, useRef } from 'react';

import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import EmailIcon from "@mui/icons-material/Email";
import PosicaoEstoqueIcon from "@mui/icons-material/Storage";
import ListIcon from "@mui/icons-material/LineWeight";
import Warning from "@mui/icons-material/Warning";
import AlmoxExternoIcon from "@mui/icons-material/OtherHouses";

import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import BarChart2 from "../../components/BarChart2";
import BarChart3 from "../../components/BarChart3";
import Line from '../../components/Line';

import StatBox from "../../components/StatBox";
import StatBoxBasic from "../../components/StatBoxBasic";

import ProgressCircle from "../../components/ProgressCircle";
import PieChart from '../../components/PieChart'

import { tituloDashBoard } from "../../data/mockData";

import ApiDashboard from '../../service/ApiDashboardService';
import ApiDashboardItensService from '../../service/ApiDashboardItensService';
import ApiPosicaoEstoqueService from '../../service/ApiPosicaoEstoqueService';
import ApiPosicaoSemMovimento from '../../service/ApiPosicaoSemMovService';
import ApiPosicaoEstoqueObsoletoService from '../../service/ApiPosicaoEstoqueObsoletoService';
import ApiAlmoxExternoService from '../../service/ApiAlmoxExternoService';
import ApiListaAlmoxService from '../../service/ApiListaAlmoxService';
import ApiEstoqueMinimoMaximoService from '../../service/ApiEstoqueMinimoMaximoServices';

import PosicaoEstoque from '../posicaoestoque';
import EstoqueSemMov from '../estoquesemmov';
import EstoqueObsoleto from '../estoqueobsoleto';
import EstoqueExterno from '../estoqueexterno';

import PieChart5 from '../../components/PieChart5';
import PieChart6 from '../../components/PieChart6';

const Dashboard = () => {

  const tempo = process.env.REACT_APP_TEMPO_EXECUSSAO

  const columns = [
    { field: "id", headerName: "Cod. Item", width: 100 },
    { field: "descricao", headerName: "Descrição", cellClassName: "name-column--cell", width: 500 },
    { field: "minimo", headerName: "Minimo", width: 100, },
    { field: "maximo", headerName: "Maximo", type: "number", headerAlign: "center", align: "left", width: 100, },
    { field: "qtd", headerName: "Qtd", width: 100 },
  ];

  const timeToCallSomething = useRef(null);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  const [service, setService] = useState(new ApiDashboard());
  const [serviceItens, setServiceItens] = useState(new ApiDashboardItensService());

  const [servicePosicaoEstoque, setPosicaoEstoque] = useState(new ApiPosicaoEstoqueService());
  const [servicePosicaoSemMov, setPosicaoSemMov] = useState(new ApiPosicaoSemMovimento());
  const [servicePosicaoObsoleto, setPosicaoObsoleto] = useState(new ApiPosicaoEstoqueObsoletoService);
  const [serviceAlmoxExterno, setAlmoxExterno] = useState(new ApiAlmoxExternoService());
  const [serviceListaAlmox, setListaAlmox] = useState(new ApiListaAlmoxService());
  const [serviceMinimoMaximo, setServiceMinimoMaximo] = useState(new ApiEstoqueMinimoMaximoService);

  const [record, setRecord] = useState([{}]);
  const [recordItens, setRecordItens] = useState([{}]);
  const [recordPosicaoEstoque, setRecordPosicaoEstoque] = useState([{}]);
  const [recordPosicaoSemMovimento, setRecordPosicaoSemMovimento] = useState([{}]);
  const [recordPosicaoObsoleto, setRecordPosicaoObsoleto] = useState([{}]);
  const [recordAlmoxExterno, setRecordAlmoxExterno] = useState([{}]);
  const [recordListaAlmox, setRecordListaAlmox] = useState([{}]);
  const [recordMinimoMaximo, setRecordMinimoMaximo] = useState([{ id: '1', descricao: 'sem movimento', minimo: 0, maximo: 0, qtd: 0 }]);

  function buscarCars() {

    service
      .consultar()
      .then(resposta => {

        setRecord(resposta.data)

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarPosicaoEstoque() {

    servicePosicaoEstoque
      .consultar()
      .then(resposta => {

        setRecordPosicaoEstoque(resposta.data)

        buscarPosicaoSemMovimento();

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarPosicaoSemMovimento() {

    servicePosicaoSemMov
      .consultar()
      .then(resposta => {

        setRecordPosicaoSemMovimento(resposta.data)

        buscarPosicaoObsoleto();

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarPosicaoObsoleto() {

    servicePosicaoObsoleto
      .consultar()
      .then(resposta => {

        setRecordPosicaoObsoleto(resposta.data)

        buscarAlmoxExterno();

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarAlmoxExterno() {

    serviceAlmoxExterno
      .consultar()
      .then(resposta => {

        setRecordAlmoxExterno(resposta.data)

        buscarListaAlmox();

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarListaAlmox() {

    serviceListaAlmox
      .consultar()
      .then(resposta => {

        setRecordListaAlmox(resposta.data)

        buscarMinimoMaximo();

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarItens() {

    serviceItens
      .consultar()
      .then(resposta => {

        setRecordItens(resposta.data)

      }).catch(error => {
        console.log(error)
      });

  }

  function buscarMinimoMaximo() {

    serviceMinimoMaximo.consultar()
      .then(resposta => {

        setRecordMinimoMaximo(resposta.data);

      }).catch(error => {
        console.log(error)
      });

  }

  const fetchSomething = () => {
    //buscarCars(); 
    //buscarItens();

    buscarPosicaoEstoque();

  };

  useEffect(() => {

    if (timeToCallSomething.current) {
      clearInterval(timeToCallSomething.current);
    }

    timeToCallSomething.current = setTimeout(fetchSomething, tempo);

    return () => clearInterval(timeToCallSomething.current);

  });

  return (

    <Box m="20px">
      {/* HEADER */}

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
      >
        <Header title="DASHBOARD" subtitle="Bem Vindo ao seu dashboard" />

      </Box>

      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          {recordPosicaoEstoque.map((transaction, i) => {
            return (
              <Box key={i}
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={"R$" + recordPosicaoEstoque[0].saldo}//{transaction.titleQuadro1}
                  subtitle={'Posição Estoque'}//{transaction.subtitleQuadro1}
                  progress={recordPosicaoEstoque[0].percentualestoque}//{transaction.progressQuadro1}
                  increase={recordPosicaoEstoque[0].sinal + recordPosicaoEstoque[0].percentualestoque + "%"}//{transaction.increaseQuadro1}
                  icon={
                    <PosicaoEstoqueIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )
          })}

        </Grid>

        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          {recordPosicaoSemMovimento.map((transaction, i) => {
            return (
              <Box key={i}
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBoxBasic
                  title={"R$" + recordPosicaoSemMovimento[0].saldo}//{transaction.titleQuadro1}
                  subtitle={'Material sem Movimento'}//{transaction.subtitleQuadro1}
                  icon={
                    <ListIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )
          })}
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          {recordPosicaoObsoleto.map((transaction, i) => {
            return (
              <Box key={i}
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBoxBasic
                  title={"R$" + transaction.saldo}//{transaction.titleQuadro1}
                  subtitle={'Material Obsoleto'}//{transaction.subtitleQuadro1}

                  icon={
                    <Warning
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )
          })}
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          {recordAlmoxExterno.map((transaction, i) => {
            return (
              <Box key={i}
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBoxBasic
                  title={"R$" + recordAlmoxExterno[0].saldo}//{transaction.titleQuadro1}
                  subtitle={'Almoxarifado Externo'}//{transaction.subtitleQuadro1}

                  icon={
                    <AlmoxExternoIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            )
          })}
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >

                <Box key={1}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Entrada Semestral de Estoque R$

                  </Typography>

                </Box>

              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={false} />
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]} p="20px" key={1}>
              <Typography
                variant="h5"
                fontWeight="600"
              >
                Entradas e saidas Semestral - Qtdade
              </Typography>
              <Box height="290px" mt="-20px">
                <BarChart isDashboard={false} />
              </Box>
            </Box>

          </Grid>

          {/*borelli acrescentei grafico de pizza*/}
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]} p="20px 0 0 0" key={1}>
              <Typography
                variant="h5"
                fontWeight="600"
              >
              </Typography>
              <Box height="332px" mt="-20px">
                <PieChart5 />
              </Box>
            </Box>
          </Grid>

        </Grid>

        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            backgroundColor={colors.primary[400]}
            maxHeight="67vh"
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              color={colors.grey[100]}
              p="15px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Posição de Estoque por Almoxarifado
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              color={colors.grey[100]}
              p="15px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                width={5}
                color={colors.grey[100]}
              >
                Almoxarifado
              </Typography>

              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
                alignItems="left"
              >
                Descrição
              </Typography>

              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Saldo
              </Typography>

            </Box>

            {recordListaAlmox.map((transaction, i) => {
              return (
                <Box
                  key={`${transaction}-${i}`}
                  display="flex"
                  justifyContent='space-between'
                  borderBottom={`1px solid ${colors.primary[500]}`}
                  p="10px"
                >
                  <Box>
                    <Typography color={colors.grey[100]} >
                      {transaction.cod_almoxarifado}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color={colors.grey[100]} >
                      {transaction.descricaoalmox}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color={colors.grey[100]} >
                      {transaction.saldo}
                    </Typography>
                  </Box>

                </Box>
              );
            })}
          </Box>

        </Grid>

      </Grid>
      {/*
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="ESTOQUE MINIMO E MAXIMO" />
        </Box>
        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.greenAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.greenAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={recordMinimoMaximo}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
   */}

      <Box m="25px 0 0 0">
        <PosicaoEstoque />
        <EstoqueSemMov />
        <EstoqueObsoleto />
        <EstoqueExterno />
      </Box>
      
    </Box>


  );
};


export default Dashboard;
