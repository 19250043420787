import ApiService from "../service/ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiEstoqueTipoAgrupa extends ApiServiceReinfProd {  

    constructor(){
        super('estoque/tipo/agrupa')  
    } 

    consultar(){ 
 
        return this.get_geral();
    } 
 
}