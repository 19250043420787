import React, { useEffect, useState, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme } from '@mui/material';
import { tokens } from '../theme';

import ApiEstoqueTipoAgrupa from '../service/ApiEstoqueTipoAgrupaService';

const PieChart5 = () => {

  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const timeToCallSomething = useRef(null);
  const tempo = process.env.REACT_APP_TEMPO_EXECUSSAO

  const [serviceEstoqueTipoAgrupa, setEstoqueTipoAgrupa] = useState(new ApiEstoqueTipoAgrupa());
  const [recordTipoAgrupa, setRecordTipoAgrupa] = useState([]);

  const [state, setState] = useState({

    series: [0],

    options: {

      title: {
        text: 'Top 7 Almoxarifados',
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: colors.grey[100]
        },
      },

      chart: {
        width: '100%',
        height: '100%',
        type: 'pie',
      },

      labels: [
        '',
      ],

      theme: {
        mode: 'light',
        palette: theme.palette.mode,
        monochrome: {
          enabled: false,
          color: colors.grey[100],
          shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },

      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },

      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex]
          return [name, Number(val.toFixed(1))
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%']
        },
        style: {
          fontSize: '10px',
        },
      },

      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '9px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: colors.grey[100],
          useSeriesColors: true
        },
        markers: {
          size: 5,
          shape: undefined,
          strokeWidth: 1,
          fillColors: undefined,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0
        },
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        },
      }

    },

  });

  useEffect(() => {

    if (timeToCallSomething.current) {
      clearInterval(timeToCallSomething.current);
    }

    timeToCallSomething.current = setTimeout(fetchSomething, tempo);

    return () => clearInterval(timeToCallSomething.current);

  });

  const fetchSomething = () => {

    buscarEstoqueTipoAgrupa();

    const descricao = [];
    const valor = [];

    for (var i of recordTipoAgrupa) {

      descricao.push(i.label);
      valor.push(i.value);

    }

    setState({

      series: valor,

      options: {

        title: {
          text: 'Top 7 Almoxarifados',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: colors.grey[100]
          },
        },

        chart: {
          width: '100%',
          height: '100%',
          type: 'pie',
        },

        labels: descricao,

        theme: {
          mode: 'light',
          palette: theme.palette.mode,
          monochrome: {
            enabled: false,
            color: colors.grey[100],
            shadeTo: 'light',
            shadeIntensity: 0.65
          },
        },

        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              offset: -5,
            },
          },
        },

        grid: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },

        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1)+ '%']
              //.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%']
          },
          style: {
            fontSize: '10px',
          },
        },

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '9px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: 0,
          height: 0,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: colors.grey[100],
            useSeriesColors: true,
          },
          markers: {
            size: 7,
            shape: undefined,
            strokeWidth: 1,
            fillColors: undefined,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        }

      },

    })

  }

  function buscarEstoqueTipoAgrupa() {

    serviceEstoqueTipoAgrupa
      .consultar()
      .then(resposta => {

        setRecordTipoAgrupa(resposta.data)

      }).catch(error => {
        console.log(error)
      });

  }

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="pie" height={350} />
      </div>
    </div>
  );
}

export default PieChart5;
