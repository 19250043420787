import ApiService from "./ApiService";
import ApiServiceReinfProd from "../service/ApiServiceReinfProd";

export default class ApiDasboardlineService extends ApiServiceReinfProd { 

    constructor(){
        super('estoque/almox/entrada')  
    } 

    consultar(){ 
  
        return this.get_geral(); 
    } 
 
}