import ApiUsuarioService from '../service/ApiUsuarioService';

export default class LogarUsuario extends ApiUsuarioService {

    constructor(){
        super('consecana/logon/usuario/') 
    }  

    consultar(filtro){ 

        let params = `${filtro.username}`

        if(filtro.username){
            params = `${params}/${filtro.senha}`
        } 
        return this.get(params);
    } 
 
}